"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var RecipeModule = /** @class */function () {
  function RecipeModule(config) {
    this.config = config;
  }
  return RecipeModule;
}();
exports.default = RecipeModule;